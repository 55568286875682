@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@500&display=swap");

.cont-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cont-img {
  background-image: url("../../Assets/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 800px;
  min-width: 400px;
  max-width: 100%;
  height: 700px;
}

.card {
  padding: 20px;
  min-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
  margin-top: 50px;
  margin-right: 20px;
}

.h2 {
  margin-bottom: 20px;
  font-size: 25px;
  font-family: 'Fredoka', sans-serif;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

@media (max-width: 480px) {
  .cont-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }

  .cont-img {
    background-image: url("../../Assets/logo-tablet.png");
    min-width: 200px;
    min-width: 300px;
  }

  .card {
    margin: 30px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .cont-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cont-img {
    background-image: url("../../Assets/logo-tablet.png");
  }

  .card {
    width: 60%;
    margin-top: 10px;
    margin-bottom: 40px;
    margin-right: 0px;
  }
}
