@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@500&display=swap');

.cont-input-style {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}

.cont-style-input {
    outline: none;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    font-family: 'Fredoka', sans-serif;
    width: 100%;
    box-sizing: border-box;
}

@media screen and (max-width: 600px) {
    .cont-style-input {
        font-size: 14px;
    }
}