@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@500&display=swap');

.cont-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn {
    background-color: #05E981;
    padding: 10px;
    border-radius: 8px;
    align-items: center;
    color: #201B2C;
    font-family: 'Fredoka', sans-serif;
    font-size: 18px;
    width: 100%;
    margin-top: 20px;
    outline: none;
}

@media screen and (max-width: 600px) {
    .btn {
        font-size: 16px;
    }
}